import React, { useEffect, useState, useRef } from "react";
import CustomNavbar from "./Navbar.jsx";
import "./HomePage.css";
import MogCard from "./MogCard.jsx";
import Mogged1 from "../assets/mogged1.jpg";
import Mogged2 from "../assets/mogged2.jpg";
import Mogged3 from "../assets/mogged3.jpg";
import Mogged4 from "../assets/mogged4.jpg";
import Mogged5 from "../assets/mogged5.jpg";
import Mogged6 from "../assets/mogged6.jpg";
import Mogged7 from "../assets/mogged7.jpg";
import Mogged8 from "../assets/mogged8.jpg";
import Mogged9 from "../assets/mogged9.jpg";
import Mogged10 from "../assets/mogged10.jpg";
import notMogged1 from "../assets/1-min.jpg";
import { Container, Row, Col } from "react-bootstrap";

import receipt from "../assets/RECEIPT.png";

const HomePage = () => {
  const [flashon, setFlashon] = useState(true);
  const [flash, setFlash] = useState(false);
  const [flashOpacity, setFlashOpacity] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const shutterSound = useRef(new Audio());
  const backgroundMusic = useRef(
    new Audio(
      "/Britney Manson - Fashion  but only best part  (Tik Tok version).mp3"
    )
  );
  const cheerSound = useRef(new Audio("CROWD CHEER SOUND EFFECT.mp3")); // Reference to the cheer sound effect

  const sadTromboneSound = useRef(
    new Audio("Sad Trombone - Sound Effect (HD).mp3")
  );
  const byebyeSound = useRef(new Audio("byebyeslowed.mp3"));

  const [choicemade, setChoicemade] = useState(false);
  const [rightChoice, setRightChoice] = useState(false);
  const [switchCards, setSwitchCards] = useState(false);
  const [correctChoicesCount, setCorrectChoicesCount] = useState(0);

  const [isSelectedCard1, setIsSelectedCard1] = useState(false);
  const [isSelectedCard2, setIsSelectedCard2] = useState(false);

  const [currentPicIndex, setCurrentPicIndex] = useState(0);
  const moggedPics = [
    Mogged1,
    Mogged2,
    Mogged3,
    Mogged4,
    Mogged5,
    Mogged6,
    Mogged7,
    Mogged8,
    Mogged9,
    Mogged10,
  ];

  const soundFiles = [
    "/camera_shutter1.mp3",
    "/camera_shutter2.mp3",
    "/camera_shutter3.mp3",
  ];

  const getRandomSoundFile = () => {
    const randomIndex = Math.floor(Math.random() * soundFiles.length);
    return soundFiles[randomIndex];
  };

  const handleChoice = (rightChoiceSelected) => {
    setTimeout(() => {
      setIsSelectedCard1(false);
      setIsSelectedCard2(false);

      setRightChoice(rightChoiceSelected);
      setChoicemade(true);

      if (rightChoiceSelected) {
        setCorrectChoicesCount((prevCount) => prevCount + 1);
        byebyeSound.current.currentTime = 0;
        byebyeSound.current.volume = 0.008;
        byebyeSound.current
          .play()
          .catch((e) => console.error("ByeBye sound play failed:", e));
      } else {
        setCorrectChoicesCount(0);
        sadTromboneSound.current.currentTime = 0;
        sadTromboneSound.current.volume = 0.008;
        sadTromboneSound.current
          .play()
          .catch((e) => console.error("Sad Trombone sound play failed:", e));
      }

      setTimeout(() => {
        setTimeout(() => {
          setCurrentPicIndex(
            (prevIndex) => (prevIndex + 1) % moggedPics.length
          );
          setChoicemade(false);
          setRightChoice(false);
          setSwitchCards(Math.random() > 0.5);
        }, 300);
      }, 300);
    }, 250);
  };

  useEffect(() => {
    backgroundMusic.current.loop = true;
    backgroundMusic.current.volume = 0;
    const targetVolume = 0.012;
    const fadeTime = 2000;
    const stepTime = 100;
    let fadeInterval;

    const playMusic = async () => {
      try {
        await backgroundMusic.current.play();
        const volumeIncrement = (targetVolume / fadeTime) * stepTime;
        let currentVolume = 0;

        fadeInterval = setInterval(() => {
          currentVolume += volumeIncrement;
          if (currentVolume >= targetVolume) {
            backgroundMusic.current.volume = targetVolume;
            clearInterval(fadeInterval);
          } else {
            backgroundMusic.current.volume = currentVolume;
          }
        }, stepTime);
      } catch (err) {
        console.error("Background music play failed:", err);
        setIsMuted(true);
      }
    };

    playMusic();

    return () => {
      if (fadeInterval) clearInterval(fadeInterval);
      backgroundMusic.current.pause(); // Pause the music
      backgroundMusic.current.currentTime = 0; // Optionally, reset its time to start
      backgroundMusic.current.src = ""; // Clear the source if you don't plan to resume it later
    };
  }, []);

  useEffect(() => {
    if (flashon) {
      const triggerFlashEffect = () => {
        const randomOpacity = 0.5 + Math.random() * 0.5;
        setFlashOpacity(randomOpacity);

        const randomVolume = 0.02 + Math.random() * 0.07;
        shutterSound.current.src = getRandomSoundFile();
        shutterSound.current.volume = isMuted ? 0 : randomVolume;

        setFlash(true);
        shutterSound.current
          .play()
          .catch((e) => console.error("Shutter sound play failed:", e));
        setTimeout(() => setFlash(false), 100);
      };

      const interval = setInterval(
        triggerFlashEffect,
        2400 + Math.random() * 3000
      );

      return () => clearInterval(interval);
    }
  }, [flashon, isMuted]);

  useEffect(() => {
    const playCheerSound = () => {
      cheerSound.current.volume = isMuted ? 0 : 0.025;

      cheerSound.current
        .play()
        .catch((e) => console.error("Cheer sound play failed:", e));
    };

    const randomTimeBetween10And25Seconds = () => 10000 + Math.random() * 15000;
    const timeout = setTimeout(
      playCheerSound,
      randomTimeBetween10And25Seconds()
    );

    return () => clearTimeout(timeout);
  }, [isMuted]);

  const toggleMute = () => {
    const newMuteState = !isMuted;
    backgroundMusic.current.muted = newMuteState;
    shutterSound.current.muted = newMuteState;
    cheerSound.current.muted = newMuteState;
    byebyeSound.current.muted = newMuteState;
    sadTromboneSound.current.muted = newMuteState;
    setIsMuted(newMuteState);
  };

  return (
    <div className="App" id="AllHomePage">
      <header>
        <CustomNavbar
          isMuted={isMuted}
          toggleMute={toggleMute}
          setFlashon={() => setFlashon((prev) => !prev)}
          setMute={setIsMuted}
        />
      </header>
      <Container id="PageContent">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col>
            <h1 id="whomogs">Who mogs who?</h1> 
          </Col>
        </Row>

        {!choicemade && correctChoicesCount < 10 && (
          <Row>
            {switchCards ? (
              <>
                <Col>
                  <MogCard
                    id="mogcard2"
                    img={notMogged1}
                    rightChoice={true}
                    handleChoice={handleChoice}
                    isSelected={isSelectedCard2}
                    toggleSelection={() => setIsSelectedCard2(!isSelectedCard2)}
                  />
                </Col>
                <Col>
                  <MogCard
                    id="mogcard1"
                    img={moggedPics[currentPicIndex]}
                    rightChoice={false}
                    handleChoice={handleChoice}
                    isSelected={isSelectedCard1}
                    toggleSelection={() => setIsSelectedCard1(!isSelectedCard1)}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <MogCard
                    id="mogcard1"
                    img={moggedPics[currentPicIndex]}
                    rightChoice={false}
                    handleChoice={handleChoice}
                    isSelected={isSelectedCard1}
                    toggleSelection={() => setIsSelectedCard1(!isSelectedCard1)}
                  />
                </Col>
                <Col>
                  <MogCard
                    id="mogcard2"
                    img={notMogged1}
                    rightChoice={true}
                    handleChoice={handleChoice}
                    isSelected={isSelectedCard2}
                    toggleSelection={() => setIsSelectedCard2(!isSelectedCard2)}
                  />
                </Col>
              </>
            )}
          </Row>
        )}
        {choicemade && rightChoice && correctChoicesCount < 10 && (
          <Col>
            <h1 id="rightChoiceText">Right Choice</h1>
          </Col>
        )}
        {choicemade && !rightChoice && (
          <Col>
            <h1 id="wrongChoiceText">
              Wrong! <br></br>You lost!!!
            </h1>
          </Col>
        )}
        {correctChoicesCount >= 10 && (
          <Col>
            <img src={receipt} id="receipt"></img>
          </Col>
        )}
      </Container>
      {flash && (
        <div
          className="flash"
          style={{ display: "block", opacity: flashOpacity }}
        />
      )}
    </div>
  );
};

export default HomePage;
